var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs8: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("IMG Fact Sheet")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c("p", [
                        _vm._v(
                          " Indien het niet mogelijk is om zelf een Excel export aan te maken, kun je deze aanvragen via helpdesk@schadedoormijnbouw.nl. "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "overflow-auto" },
                        [
                          !_vm.isLoading && _vm.kpis
                            ? _c("StatisticsView", {
                                attrs: {
                                  items: _vm.kpis,
                                  columns: _vm.columns,
                                  options: _vm.statisticsOptions
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }