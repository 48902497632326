import StatisticsView from '@/components/statistics-view/StatisticsView.vue';
import { StatisticsOptions, StatisticsColumn } from '@/components/statistics-view/StatisticsView';
import { Rpc } from '@/models/Rpc';
import { Component, Vue } from 'vue-property-decorator';

@Component<FactSheet>({
  components: {
    StatisticsView,
  },
})
export default class FactSheet extends Vue {
  public $pageTitle = 'IMG Fact sheet';

  public isLoading = true;

  public kpis = []

  protected statisticsOptions: StatisticsOptions = {
    showTotals: false,
  };

  protected columns: StatisticsColumn[] = [{
    key: 'name',
    name: 'Gegevens 2020',
    class: 'xs3',
  }, {
    key: 'value',
    name: 'Waarde',
    class: 'xs3 text-right',
    transform: (value: any) => new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value.value),
  }, {
    key: 'note',
    name: 'Toelichting',
    class: 'xs6',
  },
  ];

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.emitBreadcrumb();
    await this.fetchData();
  }

  protected async fetchData() {
    this.isLoading = true;

    const payload = {
      signature: 'management-reports:fact-sheet',
      body: {
        year: 2020,
      },
    };

    const response = await new Rpc().rpcPost(payload, false);

    this.kpis = response.data;
    this.isLoading = false;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'IMG Fact Sheet' },
        ],
      });
  }
}
