import { render, staticRenderFns } from "./FactSheet.vue?vue&type=template&id=6484970e&scoped=true&"
import script from "@/views/ReportStatistics/FactSheet/FactSheet.ts?vue&type=script&lang=ts&"
export * from "@/views/ReportStatistics/FactSheet/FactSheet.ts?vue&type=script&lang=ts&"
import style0 from "@/views/ReportStatistics/FactSheet/fact-sheet.scss?vue&type=style&index=0&id=6484970e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6484970e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6484970e')) {
      api.createRecord('6484970e', component.options)
    } else {
      api.reload('6484970e', component.options)
    }
    module.hot.accept("./FactSheet.vue?vue&type=template&id=6484970e&scoped=true&", function () {
      api.rerender('6484970e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ReportStatistics/FactSheet/FactSheet.vue"
export default component.exports